import { Box, Typography } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@lib/themes";
import { ITheme } from "@lib/themes/types";

const useStyles = makeStyles((theme: ITheme, props) => ({
  root: {
    padding: "5px 7px",
    background: theme.palette.badge.background,
    border: `1px solid ${theme.palette.badge.border}`,
    marginLeft: theme.spacing(props.m),
    borderRadius: 6,
    display: "flex",
    boxShadow: theme.palette.badge.shadow,

    "& span": {
      fontWeight: 600,
      color: `${theme.palette.badge.text} !important`,
    },
  },
}));

const IntervalIcon = ({
  interval,
  m = 3,
}: {
  interval: number | string;
  m?: number;
}) => {
  const classes = useStyles({ m });
  return (
    <Box className={classes.root}>
      <Typography variant="caption">{interval}</Typography>
    </Box>
  );
};

export default IntervalIcon;
